<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              type="search"
              name="plantCd"
              v-model="searchParam.plantCd"
            ></c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              codeGroupCd="ISSUES_ITEM_CLASS_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="issuesClassCd"
              label="품질/환경/안전보건"
              v-model="searchParam.issuesClassCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              codeGroupCd="ISSUES_ITEM_TYPE_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="issuesTypeCd"
              label="평가대상구분"
              v-model="searchParam.issuesTypeCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!--사용여부-->
            <c-select
              :comboItems="useFlagItems"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="useFlag"
              label="LBLUSEFLAG"
              v-model="searchParam.useFlag"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="기준정보 목록"
        tableId="equipmentInsp"
        :columns="grid.columns"
        :data="grid.data"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline>
            <!--추가-->
            <c-btn v-if="editable" label="LBLADD" :showLoading="true" icon="add" @btnClicked="addItem" />
            <!--저장-->
            <c-btn
              v-show="editable && grid.data.length > 0"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveItem"
              @btnCallback="saveCallback" />
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <!-- <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="true" :editable="editable" icon="remove" @btnClicked="removeItem" /> -->
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  <script>
  import { uid } from 'quasar'
  import selectConfig from '@/js/selectConfig';
  import transactionConfig from '@/js/transactionConfig';
  export default {
    name: "internal-external-issues-item",
      data() {
      return {
        grid: {
          columns: [
            {
              required: true,
              setHeader: true,
              name: 'plantCd',
              field: 'plantCd',
              label: 'LBLPLANT',
              style: 'width:100px',
              type: 'plant',
              align: 'center',
              sortable: false,
            },
            {
              required: true,
              setHeader: true,
              name: "issuesClassCd",
              field: "issuesClassCd",
              label: "품질/환경/안전보건",
              style: 'width:100px',
              align: "center",
              type: 'select',
              sortable: false,
              codeGroupCd: 'ISSUES_ITEM_CLASS_CD',
            },
            {
              required: true,
              setHeader: true,
              name: "issuesTypeCd",
              field: "issuesTypeCd",
              label: "평가대상구분",
              style: 'width:100px',
              align: "center",
              type: 'select',
              sortable: false,
              codeGroupCd: 'ISSUES_ITEM_TYPE_CD',
            },
            {
              required: true,
              name: "issuesName",
              field: "issuesName",
              label: "평가대상명",
              style: 'width:200px',
              align: "left",
              type: 'text',
              sortable: false,
            },
            {
              required: true,
              name: "useFlag",
              field: "useFlag",
              //사용여부
              label: "LBLUSEFLAG",
              align: "center",
              style: 'width:80px',
              type: 'check',
              sortable: false,
              true: 'Y',
              false: 'N'
            },
            {
              required: true,
              name: "sortOrder",
              field: "sortOrder",
              label: "정렬순서",
              style: 'width:80px',
              align: "right",
              type: 'number',
              sortable: false,
            },
          ],
          data: [],
        },
        useFlagItems: [
          { code: 'Y', codeName: '사용' },
          { code: 'N', codeName: '미사용' },
        ],
        searchParam: {
          plantCd: null,
          issuesClassCd: null,
          issuesTypeCd: null,
          useFlag: 'Y',
        },
        editable: true,
        listUrl: '',
        saveUrl: '',
        updateUrl: '',
        popupOptions: {
          target: null,
          title: "",
          visible: false,
          top: "",
          param: {},
          closeCallback: null,
        },
        isSave: false,
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {},
    methods: {
      init() {
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.sai.iei.item.list.url;
        this.saveUrl = transactionConfig.sai.iei.item.save.url;
        this.deleteUrl = transactionConfig.sai.iei.item.delete.url;
        // url setting
        // code setting
        // list setting
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      addItem() {
        this.grid.data.splice(0, 0, {
          issuesItemId: uid(),
          issuesTypeCd: null,
          issuesName: '',
          useFlag: 'Y',
          plantCd: null,
          issuesClassCd: null,
          sortOrder: this.grid.data.length + 1,
          editFlag: 'C',
          regUserId: this.$store.getters.user.userId,
        })
      },
      /* eslint-disable no-unused-vars */ 
      saveItem() {
        if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',  
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      },
      saveCallback() {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
      },
      removeItem() {
        let selectData = this.$refs['table'].selected;
        if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
          title: '확인',  
          message: '삭제하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                this.grid.data = this.$_.reject(this.grid.data, { seniorId: _item.seniorId })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
        }
      },
    },
  };
  </script>
  